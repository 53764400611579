<template>
    <el-dialog :visible="visible" :title="title" @close="handleCancel(false)" :close-on-click-modal="false" width="600px">
        <el-form :model="form" :inline="false" label-position="right" label-width="70px" @submit.native.prevent>
            <el-form-item label="供应商：">
                <el-autocomplete placeholder="请输入供应商" ref="querySupplierName" v-model.trim="form.supplierName"
                    value-key="SupplierName" :fetch-suggestions="querySearchSupplierName"
                    style="width:95%;"></el-autocomplete>
            </el-form-item>
            <el-form-item label="货号：">
                <el-autocomplete placeholder="请输入货号" ref="queryItemNo" v-model="form.itemNo" :trigger-on-focus="false"
                    value-key="ItemNo" :fetch-suggestions="querySearchItemNo" @change="handleMatchItemNo()"
                    @select="handleSeletedItemNo($event)" style="width:95%;"></el-autocomplete>
            </el-form-item>
        </el-form>
        <div class="div-spec-info" v-if="details.length > 0">
            <div class="div-check-all">
                <el-checkbox v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>
            </div>
            <div class="div-check-item" v-for="(item, idx) in details" :key="item.ProductID + '_' + idx">
                <div class="div-check-item-color" :title="item.Color" v-if="item.Color">
                    <el-checkbox v-model="item.Checked" @change="handleColorCheck(item)">{{ item.Color }}</el-checkbox>
                </div>
                <div class="div-check-item-size" v-if="item.Details.length > 0">
                    <el-checkbox v-model="size.Checked" @change="handleSizeCheck(item, size)" v-for="size in item.Details"
                        :label="size.Size" :key="item.ProductID + '-' + size.SpecID">{{ size.Size }}</el-checkbox>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel(false)">取消</el-button>
            <el-button type="primary" v-if="purchaseProducts.length <= 1" @click="handleSave(1)"
                :loading="loading1">创建后继续新增</el-button>
            <el-button type="primary" v-if="purchaseProducts.length <= 1" @click="handleSave(0)"
                :loading="loading0">创建</el-button>
            <el-button type="primary" v-if="purchaseProducts.length > 1" @click="handleSave(2)" :loading="loading2">{{
                batchButtonText
            }}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
    name: "AddGoods",
    data() {
        return {
            visible: false,
            suppliers: [],
            title: '',
            purchaseProducts: [],
            index: 0,
            checkAll: true,
            searchItems: [],
            searchSupplierItems: [],
            timeout: null,
            details: [],
            batchButtonText: '继续确认下一个',
            form: {
                supplierName: "",
                itemNo: "",
                productId: 0,
                details: {},
            },
            submitProducts: {},
            loading0: false,
            loading1: false,
            loading2: false
        };
    },
    mounted() {
        this.$nextTick(async () => {
            this.fetchSuppliers();
        });
    },
    methods: {
        init(products) {
            var that = this;
            that.purchaseProducts = products;
            that.index = 0;
            if (that.purchaseProducts.length > 1) {
                that.title = '批量新增采购商品[共' + that.purchaseProducts.length + '个货号]';
                that.batchButtonText = '继续确认下一个[' + (that.index + 1) + '/' + that.purchaseProducts.length + ']';
            } else {
                that.title = '新增采购商品';
            }
            that.loadProduct();
            that.querySearchItemNo(that.form.itemNo, that.handleMatchItemNo);
            that.visible = true;
        },
        loadProduct() {
            var that = this;
            let item = that.purchaseProducts[that.index];
            that.form.supplierName = item.SupplierName;
            that.form.itemNo = item.ItemNo;
        },
        handleCheckAll() {
            var that = this;
            for (var i = 0; i < that.details.length; i++) {
                that.details[i].Checked = that.checkAll;
                for (var j = 0; j < that.details[i].Details.length; j++) {
                    that.details[i].Details[j].Checked = that.checkAll;
                }
            }
        },
        handleColorCheck(colorItem) {
            for (var j = 0; j < colorItem.Details.length; j++) {
                colorItem.Details[j].Checked = colorItem.Checked;
            }
        },
        handleSizeCheck(colorItem, sizeItem) {
            var that = this;
            if (!sizeItem.Checked) {
                colorItem.Checked = sizeItem.Checked;
                that.$forceUpdate();
            } else {
                this.$nextTick(async () => {
                    var allChecked = true;
                    for (var j = 0; j < colorItem.Details.length; j++) {
                        if (!colorItem.Details[j].Checked) {
                            allChecked = false;
                            break;
                        }
                    }
                    colorItem.Checked = allChecked;
                    that.$forceUpdate();
                });
            }
        },
        handleSeletedItemNo(selectedRow) {
            this.form.productId = selectedRow.ProductID;
            this.details = [];
            var that = this;
            var specs = selectedRow.Specs;
            if (specs.length > 0) {
                let tempObj = {};
                for (var i = 0; i < specs.length; i++) {
                    specs[i].Checked = true;
                    if (!tempObj[specs[i].Color]) {
                        tempObj[specs[i].Color] = {
                            ProductID: selectedRow.ProductID,
                            ItemNo: selectedRow.ItemNo,
                            DefaultSupplierName: selectedRow.DefaultSupplierName,
                            Color: specs[i].Color,
                            Checked: true,
                            Details: []
                        };
                    }
                    tempObj[specs[i].Color].Details.push(specs[i]);
                }
                this.details = Object.values(tempObj);
            }
        },
        handleMatchItemNo() {
            var that = this;
            let selectedRow = that.searchItems.filter((i) => i.ItemNo.toLowerCase() == that.form.itemNo.toLowerCase());
            if (selectedRow.length > 0) {
                that.handleSeletedItemNo(selectedRow[0]);
            } else {
                that.details = [];
            }
        },
        async querySearchItemNo(query, callback) {
            var that = this;
            if (query !== '') {
                clearTimeout(that.timeout);
                that.timeout = setTimeout(async () => {//加上延时，避免段时间输入太多而请求太多次
                    const { data } = await submit("/api/purchase/getProducts", {
                        Keyword: query, GetSpecs: true, GetStock: false, FuzzySearch: true
                    });
                    that.searchItems = data;
                    if (callback != null) {
                        callback(data);
                    }
                }, 200);
            }
        },
        async querySearchSupplierName(query, callback) {
            var that = this;
            const suppliers = that.suppliers;
            var results = query ? suppliers.filter(t => t.SupplierName.toLowerCase().includes(query.toLowerCase())) : suppliers;
            callback(results || []);
        },
        //加载供应商
        async fetchSuppliers() {
            if (this.suppliers.length > 0) return;
            const { data } = await submit('/api/purchase/supplier/getList', {
                CurrentPage: 1,
                PageSize: 1000,
            });
            this.suppliers = data.datas || [];
        },
        async handleSave(flag) {
            var that = this;
            if (!that.form.supplierName || that.form.supplierName == '') {
                that.$message({ type: "error", message: "请填写供应商!" });
                return;
            }
            var hasDetails = false;
            if (that.details.length > 0) {
                for (var i = 0; i < that.details.length; i++) {
                    var colorItem = that.details[i];
                    for (var j = 0; j < colorItem.Details.length; j++) {
                        var sizeItem = colorItem.Details[j];
                        if (!sizeItem.Checked) continue;
                        if (typeof (that.submitProducts[that.form.supplierName]) == 'undefined') {
                            that.submitProducts[that.form.supplierName] = [];
                        }
                        that.submitProducts[that.form.supplierName].push({ ProductID: sizeItem.ProductID, SpecID: sizeItem.SpecID, ItemNo: sizeItem.ItemNo, PurchaseNum: 0 });
                        hasDetails = true;
                    }
                }
            }
            if (hasDetails == false) {
                that.$message({ type: "error", message: "请选择要采购的商品!" });
                return;
            }
            if (flag == 0) {
                that.loading0 = true;
                that.toAddPurchase(function () {
                    that.loading0 = false;
                    that.handleCancel(true);
                }, function (error) {
                    that.loading0 = false;
                });
            } else if (flag == 1) {
                that.loading1 = true;
                that.toAddPurchase(function () {
                    that.loading1 = false;
                    that.clearData();
                }, function (error) {
                    that.loading1 = false;
                });
            } else {
                if ((that.index + 1) < that.purchaseProducts.length) {
                    that.index++;
                    that.loadProduct();
                    that.querySearchItemNo(that.form.itemNo, that.handleMatchItemNo);
                    if ((that.index + 1) >= that.purchaseProducts.length) {
                        that.batchButtonText = '确认提交采购单';
                    } else {
                        that.batchButtonText = '继续确认下一个[' + (that.index + 1) + '/' + that.purchaseProducts.length + ']';
                    }
                } else { //提交采购单
                    that.loading2 = true;
                    that.toAddPurchase(function () {
                        that.loading2 = false;
                        that.handleCancel(true);
                    }, function (error) {
                        that.loading2 = false;
                    });
                }
            }
        },
        async toAddPurchase(callback, errorCallback) {
            var that = this;
            that.$confirm(`确定提交采购单?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                var param = [];
                for (var key in that.submitProducts) {
                    param.push({ SupplierName: key, DetailList: that.submitProducts[key] });
                }
                const result = await submit("/api/purchase/batchAdd", param).catch((error) => {
                    if (errorCallback != null) {
                        errorCallback(error);
                    }
                });
                if (result.success) {
                    that.$message({ type: "success", message: result.msg });
                }
                if (callback != null) {
                    callback();
                }
            }).catch(() => {
                //取消   
                that.loading0 = false;
                that.loading1 = false;
                that.loading2 = false;       
            });
        },
        handleCancel(flag) {
            var that = this;
            that.clearData();
            that.searchItems = [];
            that.suppliers = [];
            that.searchSupplierItems = [];
            that.submitProducts = {};
            that.batchButtonText = '继续确认下一个';
            that.visible = false;
            if (flag) {
                that.$confirm(`是否前往采购单录入采购数量?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    that.$router.push({ path: '/wms/purchase' });
                }).catch(() => {
                    //取消          
                });
            }
        },
        clearData() {
            this.form = {
                supplierName: "",
                itemNo: "",
                productId: 0,
                details: {},
            };
            this.details = [];
        }
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}

.el-form-item__content {
    margin-left: 0px;
}

.div-spec-info {
    padding: 10px 0px 0px 7px;

    .div-check-item {
        margin-top: 5px;
        margin-left: 15px;
        display: flex;

        .el-checkbox {
            margin-right: 10px;
        }

        .div-check-item-color {
            float: left;
            width: 20%;
            overflow: hidden;
        }

        .div-check-item-size {
            float: left;
            width: 80%;
            overflow: hidden;
        }
    }
}
</style>