<template>
  <el-container>
    <el-header height="90px" style="padding:5px 0 0 10px">
      <!--头部内容区域-->
      <div class="div-search-left">
        <el-form :inline="true">
          <el-form-item label="供应商：">
            <el-input v-model="queryParam.SupplierName" style="width: 147px" placeholder="请输入供应商"></el-input>
          </el-form-item>
          <el-form-item label="货号：">
            <el-input v-model="queryParam.ItemNo" style="width: 147px" placeholder="请输入货号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery()">查询</el-button>
            <el-button type="default" @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
        <el-form :inline="true">
          <el-form-item label="看数据：">
            <el-radio-group v-model="queryParam.Status" @change="handleQuery()">
              <el-radio :label="0">看全部</el-radio>
              <el-radio :label="-1">看库存不足<span class="red">({{ stat.StockLowCount }})</span></el-radio>
              <el-radio :label="1">看库存积压<span class="red">({{ stat.StockHighCount }})</span></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="div-batch-set">
        <div class="div-info">最大库存和最小库存同时0，表示不设置该商品的库存预警值</div>
        <div class="div-set">
          当前页统一设置：
          <el-input-number v-model="batchMinWarnStock" @change="handleBatchChange($event, 'min')" :controls="false"
            :min="0" style="width: 80px" placeholder="最低库存"></el-input-number>
          -
          <el-input-number v-model="batchMaxWarnStock" @change="handleBatchChange($event, 'max')" :controls="false"
            :min="0" style="width: 80px" placeholder="最高库存"></el-input-number>
        </div>
      </div>
      <div class="div-batch-button">
        <el-button type="primary" @click="handleShowAdd(null)">批量生成采购单</el-button>
      </div>
    </el-header>
    <el-main>
      <el-table ref="gridTable" :data="tableData" stripe border fit show-header :row-style="handleRowStyle"
        :cell-style="handleCellStyle" :row-key="tableConfig.rowKey" v-loading="loading" height="100%" style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="handleSortChange" @row-click="handleRowClick">
        <el-table-column type="selection" fixed="left" width="40" align="center"></el-table-column>
        <el-table-column prop="ItemNo" label="货号" fixed="left" header-align="center" align="center" width="150">
          <template slot-scope="{ row }">
            {{ row.ItemNo }}
          </template>
        </el-table-column>
        <el-table-column prop="SupplierName" label="供应商" header-align="center" align="center" width="150">
          <template slot-scope="{ row }">
            {{ row.SupplierName ? row.SupplierName : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="Stock" label="库存" header-align="center" align="center">
          <template slot="header">
            <span>库存</span>
            <el-tooltip content="当前库存:占用库存:可用库存">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <el-row>
              <el-col :span="8" title="当前库存">{{ row.Stock }}</el-col>
              <el-col :span="1">:</el-col>
              <el-col :span="7" title="占用库存">{{ row.OccupyStock }}</el-col>
              <el-col :span="1">:</el-col>
              <el-col :span="7" title="可用库存">{{ row.LeftStock }}</el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="WaitStockInNum" header-align="center" align="center">
          <template slot="header">
            <span>待入库</span>
            <el-tooltip content="待入库数量(采购/退货)">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <span title="待入库数量(采购/退货)">{{ getWaitStockInNum(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column :prop="'RecentDay' + day" v-for="day in recentDays" :key="'RecentDay' + day"
          header-align="center" align="center" sortable="custom">
          <template slot="header">
            <span>近{{ day }}天</span>
            <el-tooltip content="报单/人数">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <span title="报单/人数">{{ getRecentNum(row, ['RecentSaleNum' + day, 'RecentPersonNum' + day]) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="MinStock" label="最低库存" header-align="center" align="center" width="105">
          <template slot-scope="{ row }">
            <el-input-number style="width: 80px" :controls="false" :min="0" v-model="row.MinStock" :title="row.MinStock"
              @focus="handleSelectText($event)" @change="handleStockChange(row, 'MinStock')"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="MaxStock" label="最高库存" header-align="center" align="center" width="105">
          <template slot-scope="{ row }">
            <el-input-number style="width: 80px" :controls="false" :min="0" v-model="row.MaxStock" :title="row.MaxStock"
              @focus="handleSelectText($event)" @change="handleStockChange(row, 'MaxStock')"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="WarnText" label="库存预警" header-align="center" align="center" width="110">
          <template slot-scope="{ row }">
            <span class="red">{{ row.WarnText }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Operation" label="操作" header-align="center" align="center" width="158" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleShowAdd(row)">一键采购</el-button>
          </template>
        </el-table-column>
      </el-table>
      <add-suggest-goods ref="addGoodsDlg"></add-suggest-goods>
    </el-main>
    <el-footer height="44px">
      <el-pagination :hide-on-single-page="false" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageNo" :page-sizes="[10, 15, 20, 30, 50]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import { submit } from "@/api/base";
import { tableRowActiveColor } from "@/config";
import AddSuggestGoods from "@/views/purchase/components/AddSuggestGoods.vue";
export default {
  name: "pageList",
  components: { AddSuggestGoods },
  data() {
    return {
      routeParam: {},
      loading: false,
      tableData: [],
      stat: {
        StockHighCount: 0,
        StockLowCount: 0
      },
      pageNo: 1,
      pageSize: 15,
      total: 0,
      tableConfig: {
        url: "/api/purchase/getSuggestList",
        rowKey: "ProductID",
      },
      queryParam: {
        ItemNo: "",
        SupplierName: "",
        Status: 0,
        sortField: '',
        sortType: '',
      },
      recentDays: [3, 7, 15, 30],
      exportLoading: false,
      selectRows: [],
      batchMinWarnStock: 0,
      batchMaxWarnStock: 0,
    };
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    handleShowAdd(row) {
      var that = this;
      var products = [];
      if (row == null) {
        //批量添加
        if (that.selectRows.length <= 0) {
          that.$message({ type: "error", message: '请选择要生成采购单的商品!' });
          return;
        }
        products = [...that.selectRows];
      } else {
        //单个添加
        products.push({ ...row });
      }
      this.$refs.addGoodsDlg.init(products);
    },
    // 条数变化
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleQuery();
    },
    // 页码变化
    handleCurrentChange(current) {
      this.pageNo = current;
      this.handleQuery(true);
    },
    // 点击行
    handleRowClick(row) {
      this.$refs.gridTable.toggleRowSelection(row);
    },
    //排序发生改变
    handleSortChange({ column, prop, order }) {
      this.queryParam['sortField'] = prop;
      this.queryParam['sortType'] = order == 'descending' ? 'desc' : 'asc';
      this.pageNo = 1;
      this.handleQuery();
    },
    handleRowStyle({ row }) {
      if (this.selectRows.includes(row)) {
        return { 'background-color': tableRowActiveColor };
      }
    },
    handleCellStyle({ row }) {
      if (this.selectRows.includes(row)) {
        return {
          'background-color': tableRowActiveColor,
          'border-color': 'rgba(218, 223, 228, 1)',
        };
      }
    },
    reset() {
      this.queryParam.SupplierName = "";
      this.queryParam.ItemNo = "";
      this.queryParam.Status = 0;
      this.$refs.gridTable.clearSort();
      this.queryParam.sortField = "";
      this.queryParam.sortType = "";
      this.handleQuery();
    },
    getWaitStockInNum(row) {
      let purchaseNum = row.PurchaseNum;
      let refundNum = row.RefundNum;
      if (purchaseNum == 0 && refundNum == 0) {
        return '-';
      }
      return (purchaseNum + refundNum) + '(' + purchaseNum + '/' + refundNum + ')';
    },
    getRecentNum(row, props) {
      let num = row[props[0]];
      let person = row[props[1]];
      if (num == 0 && person == 0) {
        return '-';
      }
      return num + '件/' + person + '人';
    },
    handleSelectText(e) {
      e.currentTarget.select();
    },
    async handleBatchChange(val, type) {
      if (!val || val <= 0) {
        return;
      }
      var that = this;
      let productIds = [];
      that.tableData.forEach(x => {
        productIds.push(x.ProductID);
      });
      if (productIds.length <= 0) {
        that.$message({ type: "error", message: '没有可以设置的商品!' });
        return;
      }
      let param = { ProductIDs: productIds.join(), IsBatch: true };
      switch (type) {
        case 'min': param['MinStock'] = val; break;
        case 'max': param['MaxStock'] = val; break;
      }
      that.loading = true;
      const result = await submit(`/api/purchase/saveWarnStock`, param).catch((error) => {
        this.$message({ type: "error", message: '批量设置' + (type == 'max' ? '最高库存' : '最低库存') + '出错!' });
        switch (type) {
          case 'min': that.batchMinWarnStock = 0; break;
          case 'max': that.batchMaxWarnStock = 0; break;
        }
        that.loading = false;
      });
      if (result.success) {
        this.handleQuery(true);
        this.$message({ type: "success", message: '批量设置' + (type == 'max' ? '最高库存' : '最低库存') + '保存成功!' });
      }
      that.loading = false;
    },
    async handleStockChange(row, type) {
      let param = { ProductIDs: row.ProductID, IsBatch: false };
      param[type] = row[type];
      const result = await submit(`/api/purchase/saveWarnStock`, param).catch((error) => {
        this.handleQuery(true);
      });
      if (result.success) {
        this.$message({ type: "success", message: '货号[' + row.ItemNo + ']' + (type == 'MaxStock' ? '最高库存' : '最低库存') + '保存成功!' });
      }
      this.handleQuery(true);
    },
    async handleQuery(isCurrentChange = false) {
      var that = this;
      let searchParam = { ...that.queryParam };
      if (!isCurrentChange) {
        this.pageNo = 1;
        this.batchMinWarnStock = 0;
        this.batchMaxWarnStock = 0;
      }
      this.loading = true;
      searchParam['currentPage'] = this.pageNo;
      searchParam['pageSize'] = this.pageSize;
      const { data } = await submit(this.tableConfig.url, searchParam).catch((error) => {
        this.loading = false;
      });
      this.total = data.data.total;
      this.tableData = data.data.datas || [];
      if (data.stat) {
        this.stat.StockHighCount = data.stat.StockHighCount;
        this.stat.StockLowCount = data.stat.StockLowCount;
      }
      this.loading = false;
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-header {

    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      ::v-deep {
        .el-range-input {
          width: 70px;
        }
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    ::v-deep {

      .el-form-item__label {
        padding-right: 0;
      }
    }

    .div-search-left {
      width: 600px;
      float: left;
      margin-top: 5px;
      margin-left: 5px;
    }

    .div-batch-set {
      float: left;
      text-align: center;
      margin-left: 630px;
      margin-top: 24px;

      .div-info {
        color: #aaa;
        font-size: 12px;
      }
    }

    .div-batch-button {
      float: right;
      margin-top: 40px;
      margin-right: 30px;
    }
  }

  .el-main {
    .el-table__header th {
      background-color: #f3f7f9 !important;
      border-color: #ebeef4 !important;
    }

    .red {
      color: red;
    }
  }
}
</style>

